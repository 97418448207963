import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import EncryptUtility from '../components/utility/js/encrypt-utility'

Vue.use(Vuetify);

export default new Vuetify({ 
  theme: {
    dark:EncryptUtility.localStorageDecrypt('theme') === undefined || EncryptUtility.localStorageDecrypt('theme') === null? false : JSON.parse(EncryptUtility.localStorageDecrypt('theme')),
    // dark:false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#005ba8',
        secondary: '#f89554',       
        menubreadcrums:"#FFFFFF",
        containerbox:"#FFFFFF",
        primarytext:'#005ba8',
        navlinks: '#005ba8',
        activeColor:"#2ed8b6",
        inactiveColor:"#ff5370",
        background:"#f4f5fa",
        tertiary: '#e9e7f7',
        customwhite: '#FFFFFF',        
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        customLightGrey: '#EBEEF3', 
        cWhite: '#FFFFFF',   
        red:"#DC143C"
      },
      dark: {
        primary: '#525252',
        secondary: '#f89554',
        menubreadcrums:"#525252",
        containerbox:"#383838",
        activeColor:"#2ed8b6",
        inactiveColor:"#ff5370",
        navlinks: '#FFFFFF',
        primarytext:'#FFFFFF',
        pTableHeader:"#525252",
        pTableBody:"#383838",
        cWhite: '#FFFFFF', 
        breadcrums:"#383838",
        background:"#282828",//"#292a2d",       
        customLightGrey: '#EBEEF3',  
      },
    },
  },
});
