import Vue from "vue";
import VueRouter from "vue-router";
import EncryptUtility from "../components/utility/js/encrypt-utility";
Vue.use(VueRouter);

const routes = [
  {
    path: "",
    redirect: "/login",
    meta: { auth: true },
  },
  {
    path: "/upload-utility",
    component: () => import("../components/utility/upload-utility.vue"),
    meta: { auth: false },
  },
  {
    path: "/central-receiving",
    component: () => import("../components/receiving/central-receiving.vue"),
    meta: { auth: false },
  },
  {
    path: "/printer-settings",
    component: () => import("../components/utility/printer-utility.vue"),
    meta: { auth: true },
  },
  {
    path: "/trap-manager",
    component: () => import("../components/utility/trap-manager.vue"),
    meta: { auth: false },
  },
  {
    path: "/login",
    component: () => import("../components/login/login.vue"),
    name: "login",
    meta: { auth: true },
  },
  {
    path: "/login",
    component: () => import("../components/login/login.vue"),
    name: "login",
    meta: { auth: true },
  },
  {
    path: "/app-company",
    component: () => import("../components/company/app-company.vue"),
    meta: { auth: false },
  },
  {
    path: "/app-project",
    component: () => import("../components/company/app-project.vue"),
    meta: { auth: false },
  },
  {
    path: "/app-warehouse",
    component: () => import("../components/company/app-warehouse.vue"),
    meta: { auth: false },
  },
  {
    path: "/app-business",
    component: () => import("../components/company/app-business.vue"),
    meta: { auth: false },
  },
  {
    path: "/database-artifacts",
    component: () => import("../components/utility/database-artifacts"),
    meta: { auth: false },
  },
  {
    path: "/app-menu",
    component: () => import("../components/permission/app-menu.vue"),
    meta: { auth: false },
  },
  {
    path: "/secure-menu-settings",
    component: () => import("../components/permission/app-secure-menu.vue"),
    meta: { auth: false },
  },
  {
    path: "/user-profile",
    component: () => import("../components/user/user-profile.vue"),
    meta: { auth: true },
  },
  {
    path: "/home",
    component: () => import("../components/home/home.vue"),
    meta: { auth: true },
  },
  {
    path: "/app-role",
    component: () => import("../components/permission/app-role.vue"),
    meta: { auth: false },
  },
  {
    path: "/app-install",
    component: () => import("../components/permission/app-install.vue"),
    meta: { auth: false },
  },
  {
    path: "/app-user",
    component: () => import("../components/permission/app-user.vue"),
    meta: { auth: false },
  },
  {
    path: "/app-user-details/:id/:editUser/:deleteUser?",
    name: "appUserDetails",
    component: () => import("../components/permission/app-user-details.vue"),
    meta: { auth: false },
  },
  {
    path: "/sales-order",
    component: () => import("../components/sales-order/sales-order-list.vue"),
    meta: { auth: false },
  },
  {
    path: "/sales-order-add/:id?",
    name: "salesOrderAdd",
    component: () => import("../components/sales-order/sales-order-add.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/sales-order-details/:id?",
    name: "salesOrderDetails",
    component: () => import("../components/sales-order/sales-order-details.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/sales-order-edit/:salesOrderId?",
    name: "salesOrderEdit",
    component: () => import("../components/sales-order/sales-order-edit.vue"),
    meta: { auth: false },
  },
  {
    path: "/app-role-edit",
    component: () => import("../components/permission/app-role-edit.vue"),
    meta: { auth: false },
  },
  {
    path: "/app-role-add",
    name: "appRoleAdd",
    component: () => import("../components/permission/app-role-add.vue"),
    meta: { auth: false },
  },

  {
    path: "/app-user-add",
    component: () => import("../components/permission/app-user-add.vue"),
    meta: { auth: false },
  },
  {
    path: "/sample-forms",
    component: () => import("../components/sample/sample-forms.vue"),
    meta: { auth: false },
  },
  {
    path: "/sample-buttons",
    component: () => import("../components/sample/sample-buttons.vue"),
    meta: { auth: false },
  },
  {
    path: "/sample-icons",
    component: () => import("../components/sample/sample-icons.vue"),
    meta: { auth: false },
  },
  {
    path: "/unauthorized",
    component: () => import("../components/common/unauthorized.vue"),
    meta: { auth: true },
  },
  {
    path: "/app-part-class",
    component: () => import("../components/company/app-part-classes.vue"),
    meta: { auth: true },
  },
  {
    path: "/app-work-center",
    component: () => import("../components/company/app-work-center.vue"),
    meta: { auth: true },
  },
  {
    path: "/app-service-level",
    component: () => import("../components/company/app-service-level.vue"),
    meta: { auth: true },
  },
  {
    path: "/app-service-level-edit/:serviceLevelsEdit?",
    component: () => import("../components/company/app-service-level-edit.vue"),
    name: "appServiceLevelEdit",
    props: true,
    meta: { auth: true },
  },
  {
    path: "/app-service-level-add/:serviceLevelsAdd?",
    component: () => import("../components/company/app-service-level-add.vue"),
    name: "appServiceLevelAdd",
    props: true,
    meta: { auth: true },
  },
  {
    path: "/app-wc-movement",
    component: () => import("../components/utility/app-wc-movement.vue"),
    meta: { auth: false },
  },
  {
    path: "/app-discrepancy-list",
    component: () => import("../components/company/app-discrepancy-list"),
    meta: { auth: false },
  },
  {
    path: "/parts-add",
    component: () => import("../components/parts-editor/parts-add.vue"),
    meta: { auth: false },
  },
  {
    path: "/parts-update/:id?",
    name: "appPartsUpdate",
    component: () => import("../components/parts-editor/parts-update.vue"),
    meta: { auth: false },
  },
  {
    path: "/parts-details/:id?",
    name: "appPartsDetails",
    component: () => import("../components/parts-editor/parts-details.vue"),
    meta: { auth: false },
  },
  {
    path: "/parts-list",
    component: () => import("../components/parts-editor/parts-list.vue"),
    meta: { auth: false },
  },
  {
    path: "/reprint-label-utility",
    component: () => import("../components/utility/reprint-label-utility.vue"),
    meta: { auth: false },
  },
  {
    path: "/move-bin",
    component: () => import("../components/utility/move-bin.vue"),
    meta: { auth: false },
  },
  {
    path: "/change-warranty-status",
    component: () => import("../components/utility/change-warranty-status.vue"),
    meta: { auth: false },
  },
  {
    path: "/change-unit-identifier",
    component: () => import("../components/utility/change-unit-identifier.vue"),
    meta: { auth: false },
  },
  {
    path: "/create-lpn-label",
    component: () => import("../components/utility/create-lpn-label.vue"),
    meta: { auth: false },
  },
  {
    path: "/reprint-lpn-label",
    component: () => import("../components/utility/reprint-lpn-label.vue"),
    meta: { auth: false },
  },

  {
    path: "/app-cosmetic-grade",
    component: () => import("../components/utility/app-cosmetic-grade.vue"),
    meta: { auth: false },
  },
  {
    path: "/part-number-change",
    component: () => import("../components/utility/part-number-change.vue"),
    meta: { auth: false },
  },
  {
    path: "/restock-repair-issues",
    component: () => import("../components/utility/restock-repair-issues.vue"),
    meta: { auth: false },
  },
  {
    path: "/turnon-auto-bcn",
    component: () => import("../components/utility/turnon-auto-bcn.vue"),
    meta: { auth: false },
  },
  {
    path: "/quick-onhand-lookup",
    component: () => import("../components/utility/quick-onhand-lookup.vue"),
    meta: { auth: false },
  },
  {
    path: "/code-manager",
    component: () => import("../components/utility/code-manager.vue"),
    meta: { auth: false },
  },
  {
    path: "/bin-management",
    component: () => import("../components/utility/bin-management.vue"),
    meta: { auth: false },
  },
  {
    path: "/manage-so-types",
    component: () => import("../components/sales-order/sales-order-so-types.vue"),
    meta: { auth: false },
  },
  {
    path: "/report-issue",
    component: () => import("../components/user-feedback/report-issue.vue"),
    meta: { auth: true },
  },
  {
    path: "/feedback-tracker",
    component: () => import("../components/user-feedback/report-issue-admin.vue"),
    meta: { auth: true },
  },
  {
    path: "/app-country",
    component: () => import("../components/company/app-country.vue"),
    meta: { auth: false },
  },
  {
    path: "/sales-order-conversion-matrix",
    component: () => import("../components/sales-order/sales-order-conversion.vue"),
    meta: { auth: false },
  },
  {
    path: "/mrb-hold-reasons",
    component: () => import("../components/company/app-mrb-reason.vue"),
    meta: { auth: false },
  },
  {
    path: "/lot-detail-report",
    component: () => import("../components/receiving/lot-detail-report.vue"),
    meta: { auth: false },
  },
  {
    path: "/unresolved-tracking-numbers",
    component: () => import("../components/receiving/unresolved-tracking-numbers.vue"),
    meta: { auth: false },
  },
  {
    path: "/email-process-master",
    component: () => import("../components/utility/email-process-master"),
    meta: { auth: false },
  },
  {
    path: "/customer-list",
    component: () => import("../components/sales-order/customer-list.vue"),
    meta: { auth: false },
  },
  {
    path: "/purchase-order",
    component: () => import("../components/purchase-order/purchase-list.vue"),
    meta: { auth: false },
  },
  {
    path: "/purchase-order-add/:purchaseOrderId?",
    component: () => import("../components/purchase-order/purchase-order-add.vue"),
    meta: { auth: false },
  },
  {
    path: "/purchase-order-edit/:purchaseOrderId?",
    component: () => import("../components/purchase-order/purchase-order-edit.vue"),
    meta: { auth: false },
  },
  {
    path: "/purchase-order-details/:purchaseOrderId?",
    name: "purchaseOrderDetails",
    component: () => import("../components/purchase-order/purchase-order-details.vue"),
    meta: { auth: false },
  },
  {
    path: "/fulfillment-audit-report",
    component: () => import("../components/utility/fulfillment-audit-report.vue"),
    meta: { auth: false },
  },
  {
    path: "/email-process-mapper",
    component: () => import("../components/system-settings/email-process-mapper.vue"),
    meta: { auth: false },
  },
  {
    path: "/purchase-matrix-operations",
    component: () => import("../components/purchase-order/purchase-matrix-operations.vue"),
    meta: { auth: false },
  },
  {
    path: "/vendor-list",
    component: () => import("../components/purchase-order/vendor-list.vue"),
    meta: { auth: false },
  },
  {
    path: "/fulfillment-hold-release",
    component: () => import("../components/shipping/fulfillment-hold-release.vue"),
    meta: { auth: false },
  },
  {
    path: "/reprint-shipping-label",
    component: () => import("../components/shipping/reprint-shipping-label.vue"),
    meta: { auth: false },
  },
  {
    path: "/shipping-queue",
    component: () => import("../components/shipping/shipping-queue.vue"),
    meta: { auth: false },
  },
  {
    path: "/test",
    component: () => import("../olp-components/admin/test.vue"),
    meta: { auth: false },
  },
  {
    path: "/reprint-packslip",
    component: () => import("../components/shipping/reprint-packslip.vue"),
    meta: { auth: false },
  },
  {
    path: "/generate-pull-sheet",
    component: () => import("../components/shipping/generate-pull-sheet.vue"),
    meta: { auth: false },
  },
  {
    path: "/update-fulfillment-waybill",
    component: () => import("../components/shipping/update-fulfillment-waybill.vue"),
    meta: { auth: false },
  },
  {
    path: "/shipping-audit-queue",
    component: () => import("../components/shipping/shipping-audit-queue.vue"),
    meta: { auth: false },
  },
  {
    path: "/shipping-audit-queue-details/:auditID?",
    name: "shippingAuditQueueDetails",
    component: () => import("../components/shipping/shipping-audit-queue-details.vue"),
    meta: { auth: false },
  },
  {
    path: "/ooba-rate-settings",
    component: () => import("../components/utility/ooba-rate-settings.vue"),
    meta: { auth: false },
  },
  {
    path: "/manage-po-types",
    component: () => import("../components/purchase-order/manage-po-types.vue"),
    meta: { auth: false },
  },
  {
    path: "/bulk-lpn",
    component: () => import("../components/shipping/bulk-lpn.vue"),
    meta: { auth: false },
  },
  {
    path: "/shipping-fulfillment/:id?",
    name: "shippingFulfillment",
    component: () => import("../components/shipping/shipping-fulfillment.vue"),
    props: true,
    meta: { auth: false },
  },
  {
    path: "/move",
    component: () => import("../components/material-movement/move.vue"),
    meta: { auth: false },
  },
  {
    path: "/pulled-hardware-queue",
    component: () => import("../components/usb/app-pulled-hardware.vue"),
    meta: { auth: false },
  },
  {
    path: "/pulled-hardware-queue/:type",
    component: () => import("../components/usb/app-pulled-hardware-queue.vue"),
    meta: { auth: false },
    name: "pulledHardwareQueue",
  },
  {
    path: "/bulk-shipping",
    component: () => import("../components/usb/bulk-shipping.vue"),
    meta: { auth: false },
    name: "bulkShipping",
  },
  {
    path: "/activity-tracker",
    component: () => import("../components/activity-loggers/activity-logger.vue"),
    meta: { auth: false },
  },
  {
    path: "/carrier-master",
    component: () => import("../components/company/carrier-master.vue"),
    meta: { auth: false },
  },
  {
    path: "/bulk-inventory-moves",
    component: () => import("../components/utility/app-bulk-move.vue"),
    meta: { auth: false },
  },
  {
    path: "/stock-status-report",
    component: () => import("../components/reports/stock-status-report.vue"),
    meta: { auth: false },
  },
  {
    path: "/parts-due-report",
    component: () => import("../components/reports/parts-due-report.vue"),
    meta: { auth: false },
  },
  {
    path: "/so-shipping-report",
    component: () => import("../components/reports/sales-order-shipments.vue"),
    meta: { auth: false },
  },
  {
    path: "/po-shipping-report",
    component: () => import("../components/reports/purchase-order-shipment.vue"),
    meta: { auth: false },
  },
  {
    path: "/stock-status-report-details",
    name: "stockStatusReportDetails",
    component: () => import("../components/reports/stock-status-report-details.vue"),
    meta: { auth: false },
  },
  {
    path: "/sales-order-receipts",
    component: () => import("../components/reports/sales-order-receipts.vue"),
    meta: { auth: false },
  },
  {
    path: "/manual-allocation",
    component: () => import("../components/utility/manual-allocation.vue"),
    meta: { auth: false },
  },
  {
    path: "/parts-by-location",
    component: () => import("../components/reports/parts-by-location.vue"),
    meta: { auth: false },
  },
  {
    path: "/purchase-order-receipts",
    component: () => import("../components/reports/purchase-order-receipts.vue"),
    meta: { auth: false },
  },
  {
    path: "/daily-snapshot",
    component: () => import("../components/reports/daily-snapshot.vue"),
    meta: { auth: false },
  },
  {
    path: "/repair-disposition-report",
    component: () => import("../components/reports/repair-disposition-report.vue"),
    meta: { auth: false },
  },
  {
    path: "/art-status-report",
    component: () => import("../components/reports/usb-reports/art-status-report.vue"),
    meta: { auth: false },
  },
  {
    path: "/productivity-analysis-report",
    component: () => import("../components/reports/productivity-analysis-report.vue"),
    meta: { auth: false },
  },
  {
    path: "/open-so-detail-report",
    component: () => import("../components/reports/open-so-detail-report.vue"),
    meta: { auth: false },
  },
  {
    path: "/country-state",
    component: () => import("../components/company/app-country-state.vue"),
    meta: { auth: false },
  },
  {
    path: "/process-throughput-summary",
    component: () => import("../components/reports/process-throughput-summary.vue"),
    meta: { auth: false },
  },
  {
    path: "/open-bto-status",
    component: () => import("../components/reports/open-bto-status.vue"),
    meta: { auth: false },
  },
  {
    path: "/discrepancy-report",
    component: () => import("../components/reports/discrepancy-report.vue"),
    meta: { auth: false },
  },
  {
    path: "/usb-parts-matrix-upload",
    component: () => import("../components/usb/usb-parts-matrix-upload.vue"),
    meta: { auth: false },
  },
  {
    path: "/pick-pack-ship",
    component: () => import("../components/reports/pick-pack-queue.vue"),
    meta: { auth: false },
  },
  {
    path: "/completed-bto-report",
    component: () => import("../components/reports/completed-bto-report.vue"),
    meta: { auth: false },
  },
  {
    path: "/lpn-audit-history-report",
    component: () => import("../components/reports/lpn-audit-history-report.vue"),
    meta: { auth: false },
  },
  {
    path: "/create-spool-lpns",
    component: () => import("../components/material-movement/create-spool-lpns.vue"),
    meta: { auth: false },
  },
  {
    path: "/lpn-consolidation",
    component: () => import("../components/material-movement/lpn-consolidation.vue"),
    meta: { auth: false },
  },
  {
    path: "/lpn-details",
    component: () => import("../components/material-movement/lpn-details.vue"),
    meta: { auth: false },
  },
  {
    path: "/lpn-activity-report",
    component: () => import("../components/material-movement/lpn-activity-report.vue"),
    meta: { auth: false },
  },
  {
    path: "/in-transit-report",
    component: () => import("../components/material-movement/in-transit-report.vue"),
    meta: { auth: false },
  },
  {
    path: "/fulfillment-picking-queue",
    component: () => import("../components/shipping/fulfillment-picking-queue.vue"),
    meta: { auth: false },
  },
  {
    path: "/fulfillment-picking-queue-details",
    name: "fulfillmentPickingQueueDetails",
    component: () => import("../components/shipping/fulfillment-picking-queue-details.vue"),
    meta: { auth: false },
  },
  {
    path: "/put-away",
    component: () => import("../components/material-movement/put-away.vue"),
    meta: { auth: false },
  },
  {
    path: "/remove-picked-lpn",
    component: () => import("../components/material-movement/remove-picked-lpn.vue"),
    meta: { auth: false },
  },
  {
    path: "/return-order-consolidation",
    component: () => import("../components/material-movement/return-order-consolidation.vue"),
    meta: { auth: false },
  },
  {
    path: "/asset-history-report",
    component: () => import("../components/reports/asset-history-report.vue"),
    meta: { auth: false },
  },
  {
    path: "/usb-asset-tracking",
    name: "USBAssetTracking",
    component: () => import("../components/usb/usb-asset-tracking.vue"),
    meta: { auth: false },
  },
  {
    path: "/usb-asset-tracking-details",
    name: "USBAssetTrackingDetails",
    component: () => import("../components/usb/usb-asset-tracking-details.vue"),
    meta: { auth: false },
  },
  {
    path: "/scope-manager-list",
    component: () => import("../components/inventory/scope-manager/scope-list.vue"),
    meta: { auth: false },
  },
  {
    path: "/scope-manager-add",
    component: () => import("../components/inventory/scope-manager/scope-add.vue"),
    meta: { auth: false },
  },
  {
    path: "/scope-manager-edit/:id?",
    component: () => import("../components/inventory/scope-manager/scope-edit.vue"),
    meta: { auth: false },
  },
  {
    path: "/scan-units",
    component: () => import("../components/inventory/scan-units.vue"),
    meta: { auth: false },
  },
  {
    path: "/bin-tabular-report",
    component: () => import("../components/inventory/bin-tabular-report.vue"),
    meta: { auth: false },
  },
  {
    path: "/bin-count-detail",
    component: () => import("../components/inventory/bin-count-detail.vue"),
    meta: { auth: false },
  },
  {
    path: "/scanned-pin-part",
    component: () => import("../components/inventory/scan-bin-part.vue"),
    meta: { auth: false },
  },
  {
    path: "/missing-inventory-detail",
    component: () => import("../components/inventory/missing-inventory.vue"),
    meta: { auth: false },
  },
  {
    path: "/remove-unit-from-scan",
    component: () => import("../components/inventory/remove-unit-from-scan.vue"),
    meta: { auth: false },
  },
  {
    path: "/clear-bins-from-scan",
    component: () => import("../components/inventory/clear-bins-from-scan.vue"),
    meta: { auth: false },
  },
  {
    path: "/print-audit-sheets",
    component: () => import("../components/inventory/print-audit-sheets.vue"),
    meta: { auth: false },
  },
  {
    path: "/fulfillment-audit-report-reports",
    component: () => import("../components/reports/fulfillment-audit-report.vue"),
    meta: { auth: false },
  },

  //Receiving
  {
    path: "/discrepancy-resolution",
    component: () => import("../components/receive/discrepancy-resolution.vue"),
    meta: { auth: false },
  },

{
  path: "/receive",
  component: () => import("../components/receive/receiving.vue"),
  meta: { auth: false },
},

{
  path: "/receiving-list/:receivingType?",
  component: () => import("../components/receive/receiving-list-comp.vue"),
  meta: { auth: false },
},



  //Olp Pages
  {
    path: "/olp-company",
    name: "olpCompany",
    component: () => import("../olp-components/admin/app-company.vue"),
    meta: { auth: false },
  },
  {
    path: "/olp-parts",
    name: "olpParts",
    component: () => import("../olp-components/admin/parts.vue"),
    meta: { auth: false },
  },
  {
    path: "/olp-business",
    name: "olpBusiness",
    component: () => import("../olp-components/admin/app-business.vue"),
    meta: { auth: false },
  },
  {
    path: "/olp-order-cart",
    name: "orderCart",
    component: () => import("../olp-components/orders/order.vue"),
    meta: { auth: false },
  },
  {
    path: "/my-orders",
    name: "myOrders",
    component: () => import("../olp-components/orders/my-orders.vue"),
    meta: { auth: false },
  },
  {
    path: "/olp-order-checkout",
    name: "orderCheckOut",
    component: () => import("../olp-components/orders/cart.vue"),
    meta: { auth: false },
  },
  {
    path: "/olp-my-cart",
    name: "myCartList",
    component: () => import("../olp-components/orders/cart-list.vue"),
    meta: { auth: false },
  },
  {
    path: "/olp-order-approval",
    name: "approver",
    component: () => import("../olp-components/approval/approver.vue"),
    meta: { auth: false },
  },
  {
    path: "/olp-dashboard",
    name: "dashboard",
    component: () => import("../olp-components/approval/dashboard.vue"),
    meta: { auth: false },
  },

  //BTO-List

  {
    path: "/bto-in-progress",
    name: "btoList",
    component: () => import("../bto-components/bto-list.vue"),
    meta: { auth: false },
  },
  {
    path: "/bto-details/:id?",
    name: "btoDetails",
    component: () => import("../bto-components/bto-details.vue"),
    meta: { auth: false },
  },

  {
    path: "*",
    component: () => import("../components/common/not-found.vue"),
    meta: { auth: true },
  },
];
const router = new VueRouter({
  mode: "history",
  routes,
});
window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});
router.afterEach((to, from) => {
  if (from.path !== to.path && from.path !== "/") {
    EncryptUtility.localStorageEncrypt("LastRoute", JSON.stringify(from.path));
    EncryptUtility.localStorageEncrypt("PresentRoute", JSON.stringify(to.path));
  }
});
router.beforeEach((to, from, next) => {
  //const token = EncryptUtility.localStorageDecrypt("Token");
  const expTime = EncryptUtility.localStorageDecrypt("JWTExp");
  const timeInt = expTime || 0;
  const jwtexp = new Date(timeInt * 1000).getTime();
  const currTime = new Date().getTime();
  // const IsItABackButton = window.popStateDetected;
  // window.popStateDetected = true;
  if (to.name != "login" && jwtexp <= currTime) {
    next({ name: "login" });
    return;
  }
  // else if (IsItABackButton) {
  //   next(false);
  //   return "";
  // }
  else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (
    (EncryptUtility.localStorageDecrypt("Url") !== undefined || EncryptUtility.localStorageDecrypt("Url") !== null) &&
    to.path != "/unauthorized" &&
    to.path != "/home"
  ) {
    let urlArray = EncryptUtility.localStorageDecrypt("Url");
    let splitpath = to.path.split("/")[1];
    if (urlArray !== undefined) {
      urlArray = JSON.parse(urlArray);
      let array = urlArray.filter((menu) => menu == splitpath);
      if (array.length > 0 || to.path == "/login") to.meta.auth = true;
      else to.meta.auth = false;
    }
  }
  if (to.meta.auth && to.path !== "/login" && to.matched[0].path !== "*") next();
  else if (!to.meta.auth && to.matched[0].path !== "*") next("/unauthorized");
  else if (to.meta.auth && to.matched[0].path === "*") next("*");
  else {
    next();
  }
});

export default router;
